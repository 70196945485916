import { Route, Routes } from "react-router-dom";
import Home from "./Pages/Home/Home";
import Header from "./Base/Header";
import Staking from "./Pages/Staking/Staking";
import { useRealTimeAD, useRealTimeBalance } from "interfaces/Utils";
import { useEffect, useState } from "react";
import ErrorAlert from "Base/Modals/ErrorAlert";
import usePageTracking from "./usePageTracking";
import ModalWrapper from "Base/Modals/ModalWrapper";
import { TxState, TxIDs } from "Base/Modals/ModalWrapper";
import axios from "axios";
import { useBlock } from "wagmi";
import { Addresses } from "index";
import { useAllowances } from "interfaces/Allowances";

const notifs = async (defender, lastPid) => {
	if (!defender) return [];

	try {
		console.log("Fetching notifications...");
		const response = await axios.get(
			`https://pstzbwybmck4d6t5hfzsbmreay0qalka.lambda-url.us-east-1.on.aws/?query_type=attack_outcomes&defender=${defender}&pid=${lastPid}`
		);
		return response.data;
	} catch (error) {
		console.error("Failed to fetch notifications: ", error);
		return JSON.parse("[]");
	}
};

const FETCH_NOTIFICATION_INTERVAL = 20 * 1000;

export default function RealApp(props) {
	const {
		account,
		createAttack,
		totalCki,
		totalFdg,
		ckiList,
		fdgList,
		compete,
		ad,
		gCki,
		cki,
		lockingCki,
		stakingCki,
		utils,
		lockingFdg,
		ckiStakeApy,
		fdgStakeApy,
		fdg,
		stakingFdg,
		ccDistr,
		balance,
	} = props;

	const [modal, setModal] = useState(null);
	const [cookieBanner, setCookieBanner] = useState(false);
	const [errorAlert, setAlert] = useState("");
	const [approveState, setApproveState] = useState(TxState.LOADING);
	const { data: blockMeta, refetch: refetchTimestamp } = useBlock({
		query: {
			refetchIntervalInBackground: true,
			refetchInterval: 10000,
		},
	});
	const [txs, setTxs] = useState([]);

	const [attackNotifs, setAttackNotifs] = useState([]);
	const allowances = useAllowances(account);

	const removeTx = (id) => {
		setTxs((prevModals) => prevModals.filter((modal) => modal.id !== id));
	};

	const isTxAvailable = (id) => {
		const tx = txs.find((tx) => tx.id === id);
		return !tx || tx.state === TxState.SUCCESS || tx.state === TxState.FAILURE;
	};

	const addTx = (id, type, title, amount) => {
		if (id == undefined) {
			console.error("Unknown TX ID for popup");
		}

		setTxs((prevModals) => {
			const index = prevModals.findIndex((modal) => modal.id === id);

			if (index !== -1) {
				// Overwrite existing modal
				const updatedModals = [...prevModals];
				updatedModals[index] = {
					id: id,
					type: type,
					title: title,
					amount: amount,
					state: TxState.LOADING,
				};
				console.warn("TX already exists in the list. Overwriting previous one.");
				return updatedModals;
			} else {
				// Add new modal
				return [
					...prevModals,
					{
						id: id,
						type: type,
						title: title,
						amount: amount,
						state: TxState.LOADING,
					},
				];
			}
		});
	};

	useEffect(() => {
		if (!account) {
			setApproveState(TxState.LOADING);
			setAttackNotifs([]);
			return;
		}

		const initializeNotifications = async (account) => {
			if (!account) {
				setAttackNotifs([]);
				return;
			}

			// Get cached notifications from localStorage
			const cachedNotifs = JSON.parse(localStorage.getItem(`notifs_${account}`)) || [];
			const lastCachedPid = cachedNotifs.length > 0 ? cachedNotifs[0].pid : 0;
			// Fetch new notifications
			const notifsData = await notifs(account, lastCachedPid);

			if (notifsData && notifsData.length > 0) {
				let newNotifsIndex = 0;
				while (newNotifsIndex < notifsData.length) {
					if (notifsData[newNotifsIndex].pid <= lastCachedPid) break;
					newNotifsIndex++;
				}

				// Get only the new notifications and mark them as new
				const newNotifs = notifsData.slice(0, newNotifsIndex).map((notif) => ({
					...notif,
					new: cachedNotifs.length === 0 ? false : true,
				}));
				const updatedNotifs = [...newNotifs, ...cachedNotifs];

				// Update state
				if (attackNotifs.length < notifsData.length) {
					// Update localStorage with new data
					localStorage.setItem(`notifs_${account}`, JSON.stringify(updatedNotifs));
					setAttackNotifs(updatedNotifs);
				} else {
					setAttackNotifs(cachedNotifs);
				}
			} else {
				setAttackNotifs(cachedNotifs);
			}
		};

		initializeNotifications(account);

		const intervalId = setInterval(
			initializeNotifications,
			FETCH_NOTIFICATION_INTERVAL,
			account
		);

		return () => clearInterval(intervalId);
	}, [account]);

	const markNotifsAsRead = () => {
		const updatedNotifs = attackNotifs.map((notif) => ({ ...notif, new: false }));
		setAttackNotifs(updatedNotifs);
		localStorage.setItem(`notifs_${account}`, JSON.stringify(updatedNotifs));
	};

	useEffect(() => {
		if (modal || cookieBanner) {
			document.body.classList.add("active");
		} else {
			document.body.classList.remove("active");
		}
	}, [modal, cookieBanner]);

	const ckiStakingClaimableRT = useRealTimeBalance(
		stakingCki.claimable,
		stakingCki.ownStake,
		stakingCki.totalStake,
		utils.ckiStakeRev,
		blockMeta?.timestamp
	);

	const fdgStakingClaimableRT = useRealTimeBalance(
		stakingFdg.claimable,
		stakingFdg.ownStake,
		stakingFdg.totalStake,
		utils.fdgStakeRev,
		blockMeta?.timestamp
	);
	const ckiLockingClaimableRT = useRealTimeBalance(
		lockingCki.claimable,
		lockingCki.ownStake,
		lockingCki.totalStake,
		utils.ckiLockRev,
		blockMeta?.timestamp
	);

	const fdgLockingClaimableRT = useRealTimeBalance(
		lockingFdg.claimable,
		lockingFdg.ownStake,
		lockingFdg.totalStake,
		utils.fdgLockRev,
		blockMeta?.timestamp
	);

	const adBalRT = useRealTimeAD(compete.ap, compete.ckiBlockedAp, blockMeta?.timestamp);

	const [soundEffects, setSoundEffects] = useState({});
	useEffect(() => {
		let map = {};
		map["launchAttack"] = new Audio("/audio/sword-swing.wav");
		map["attackWon"] = new Audio("audio/jingle-win-fanfare.wav");
		map["attackLost"] = new Audio("audio/jingle-lose-fanfare.wav");
		// map["switchPage"] = new Audio("audio/switch-17.wav");
		// map["openStaking"] = new Audio("audio/switch-14.wav");
		map["claim"] = new Audio("/audio/money.wav");
		map["coin"] = map["claim"];
		map["error"] = new Audio("/audio/error.wav");
		map["click"] = new Audio("/audio/reg-click-sound.wav");
		map["boost"] = new Audio("/audio/boost.wav");
		map["search"] = new Audio("/audio/search.wav");

		map["increase"] = new Audio("/audio/increase.wav");
		map["decrease"] = new Audio("/audio/decrease.wav");

		// legacy:
		map["switchPage"] = map["click"];
		map["openStaking"] = map["click"];

		setSoundEffects(map);
	}, []);

	usePageTracking();

	const refetchers = {
		[TxIDs.CLAIM_CKI_LOCK]: [lockingCki.refetch, cki.refetch, refetchTimestamp],
		[TxIDs.CLAIM_FDG_LOCK]: [lockingFdg.refetch, fdg.refetch, refetchTimestamp],
		[TxIDs.CLAIM_CKI_STAKING]: [stakingFdg.refetch, cki.refetch, refetchTimestamp],
		[TxIDs.CLAIM_FDG_STAKING]: [stakingCki.refetch, fdg.refetch, refetchTimestamp],
		[TxIDs.SET_DEFENCE]: [compete.refetch, cki.refetch],
		[TxIDs.SET_DOUGH]: [compete.refetch, cki.refetch],
		[TxIDs.ATTACK]: [compete.refetch, lockingCki.refetch, lockingFdg.refetch],
		[TxIDs.CLAIM_ALL]: [
			cki.refetch,
			fdg.refetch,
			lockingCki.refetch,
			lockingFdg.refetch,
			stakingCki.refetch,
			stakingFdg.refetch,
		],
		[TxIDs.MINT]: [cki.refetch, fdg.refetch],
		[TxIDs.SET_GCKI]: [gCki.refetch, cki.refetch],
		[TxIDs.LOCK_CKI]: [lockingCki.refetch, cki.refetch],
		[TxIDs.LOCK_FDG]: [lockingFdg.refetch, fdg.refetch],
		[TxIDs.STAKE_CKI]: [stakingCki.refetch, cki.refetch],
		[TxIDs.STAKE_FDG]: [stakingFdg.refetch, fdg.refetch],
		[TxIDs.SEARCH]: [compete.refetch],
		[TxIDs.UNLOCK_CKI]: [lockingCki.refetch, cki.refetch],
		[TxIDs.UNLOCK_FDG]: [lockingFdg.refetch, fdg.refetch],
		[TxIDs.UNSTAKE_CKI]: [stakingCki.refetch, cki.refetch],
		[TxIDs.UNSTAKE_FDG]: [stakingFdg.refetch, fdg.refetch],
		[TxIDs.APPROVE]: [],
	};

	const initialMint = async () => {
		soundEffects["claim"].play();
		addTx(TxIDs.MINT, "Initial mint", "Minting CKI & FDG", "");
		await ccDistr.mint();
		approveAll(0, true);
	};

	const approveAll = async (min = 0n, force = false) => {
		if (!force && approveState === TxState.SUCCESS) return;
		console.log("Approving all spendings");
		const val = 10 ** 9 * 10 ** 18;

		addTx(TxIDs.APPROVE, "Approving contracts", "Game in preparation", "");
		setApproveState(TxState.LOADING);
		allowances.refetch();
		try {
			if (allowances.ckiStaking <= min) {
				await cki.approve(Addresses.CCStakingCKI, val);
			}
			if (allowances.ckiLocking <= min) {
				await cki.approve(Addresses.CCLockingCKI, val);
			}
			if (allowances.ckiCompete <= min) {
				await cki.approve(Addresses.CCCompete, val);
			}
			if (allowances.ckiGcki <= min) {
				await cki.approve(Addresses.GCKI, val);
			}
			if (allowances.fdgStaking <= min) {
				await fdg.approve(Addresses.CCStakingFDG, val);
			}
			if (allowances.fdgLocking <= min) {
				await fdg.approve(Addresses.CCLockingFDG, val);
			}
		} catch (e) {
			setApproveState(TxState.FAILURE);
			console.log(e);
			console.log("Error while approving contract: re-approving...");
			await approveAll(min, force);
			return;
		}
		setApproveState(TxState.SUCCESS);
		console.log("All spendings approved");
	};

	return (
		<>
			<Header
				soundEffects={soundEffects}
				markNotifsAsRead={markNotifsAsRead}
				addTx={addTx}
				account={account}
				balCki={totalCki}
				balFdg={totalFdg}
				ckiList={ckiList}
				fdgList={fdgList}
				fdgLockedClaimable={fdgLockingClaimableRT}
				fdgStakedClaimable={fdgStakingClaimableRT}
				ckiLockedClaimable={ckiLockingClaimableRT}
				ckiStakedClaimable={ckiStakingClaimableRT}
				claimAll={utils.claimAll}
				setModal={setModal}
				hasMinted={ccDistr.hasMinted}
				attackNotifs={attackNotifs}
			/>

			<Routes>
				<Route
					path="/"
					element={
						<Home
							addTx={addTx}
							isTxAvailable={isTxAvailable}
							setAlert={setAlert}
							setCookieBanner={setCookieBanner}
							cookieBanner={cookieBanner}
							setModal={setModal}
							approveAll={approveAll}
							soundEffects={soundEffects}
							ckiInfo={{ name: cki.name, nonce: cki.nonce }}
							fdgInfo={{ name: fdg.name, nonce: fdg.nonce }}
							modal={modal}
							mint={ccDistr.mint}
							account={account}
							adBlockedCKI={compete.ckiBlockedAp}
							defense={compete.defenseBal}
							setDefence={compete.setDefense}
							setDefenceState={compete.setDefenseState}
							ad={compete.ap}
							adObj={ad}
							approveState={ad.approveState}
							searchState={compete.searchState}
							createAttack={createAttack}
							attackState={compete.attackState}
							attackCount={compete.attackCount}
							realTimeAdBal={adBalRT}
							ckiUnused={cki.balance}
							fdgUnused={fdg.balance}
							ckiList={ckiList}
							fdgList={fdgList}
							balCki={totalCki}
							balFdg={totalFdg}
							setAd={compete.setApGen}
							setAdState={compete.setApGenState}
							adDay={compete.apPerDay}
							ckiApprove={cki.approveFunc}
							ckiLockedRaw={lockingCki.rawStake}
							ckiLockedStake={lockingCki.ownStake}
							ckiLockedTotal={lockingCki.totalStake}
							ckiLockedTotalEarned={lockingCki.totalEarned}
							ckiLockedClaimable={lockingCki.claimable}
							ckiStakedClaimable={stakingCki.claimable}
							ckiLock={lockingCki.lock}
							ckiLockState={lockingCki.lockState}
							ckiUnlock={lockingCki.unlock}
							ckiUnlockState={lockingCki.unlockState}
							ckiLockedClaim={lockingCki.claim}
							ckiLockedClaimState={lockingCki.claimState}
							ckiLockRev={utils.ckiLockRev}
							ckiLeague={lockingCki.league}
							fdgLockedRaw={lockingFdg.rawStake}
							fdgLockedStake={lockingFdg.ownStake}
							fdgLockedTotal={lockingFdg.totalStake}
							fdgLockedTotalEarned={lockingFdg.totalEarned}
							fdgLockedClaimable={lockingFdg.claimable}
							fdgStakedClaimable={stakingFdg.claimable}
							fdgLock={lockingFdg.lock}
							fdgLockState={lockingFdg.lockState}
							fdgUnlock={lockingFdg.unlock}
							fdgUnlockState={lockingFdg.unlockState}
							fdgLockedClaim={lockingFdg.claim}
							fdgLockedClaimState={lockingFdg.claimState}
							fdgLockRev={utils.fdgLockRev}
							fdgLeague={lockingFdg.league}
							newOpponents={compete.drawOpponents}
							ckiStakeApy={ckiStakeApy}
							fdgStakeApy={fdgStakeApy}
							ckiLockingClaimableRT={ckiLockingClaimableRT}
							fdgLockingClaimableRT={fdgLockingClaimableRT}
							gCkiDeposit={gCki.deposit}
							gCkiEnd={gCki.end}
							gCkiBal={gCki.balance}
							changeGCki={utils.deposit}
							withdraw={gCki.withdraw}
							gCkiChangeState={utils.gCkiChangeState}
							claimAll={utils.claimAll}
							hasMinted={ccDistr.hasMinted}
							fdgStakedTotal={stakingFdg.totalStake}
							ckiStakedTotal={stakingCki.totalStake}
							ckiStakeRev={utils.ckiStakeRev}
							fdgStakeRev={utils.fdgStakeRev}
						/>
					}
				/>
				<Route
					path="/staking"
					element={
						<Staking
							addTx={addTx}
							setAlert={setAlert}
							account={account}
							modal={modal}
							setModal={setModal}
							balCki={totalCki}
							ckiInfo={{ name: cki.name, nonce: cki.nonce }}
							fdgInfo={{ name: fdg.name, nonce: fdg.nonce }}
							soundEffects={soundEffects}
							balFdg={totalFdg}
							ckiList={ckiList}
							fdgList={fdgList}
							ckiApprove={cki.approve}
							ckiUnused={cki.balance}
							fdgUnused={fdg.balance}
							ckiStaked={stakingCki.ownStake}
							ckiStakeRev={utils.ckiStakeRev}
							ckiTotalStaked={stakingCki.totalStake}
							fdgStaked={stakingFdg.ownStake}
							fdgStakeRev={utils.fdgStakeRev}
							fdgTotalStaked={stakingFdg.totalStake}
							fdgClaimable={stakingCki.claimable}
							ckiClaimable={stakingFdg.claimable}
							fdgTotalEarned={stakingCki.totalEarned}
							ckiTotalEarned={stakingFdg.totalEarned}
							stakeCki={stakingCki.stake}
							stakeCkiState={stakingCki.stakeState}
							stakeCkiClaim={stakingCki.claim}
							stakeCkiClaimState={stakingCki.claimState}
							unstakeCki={stakingCki.unstake}
							unstakeCkiState={stakingCki.unstakeState}
							stakeFdg={stakingFdg.stake}
							stakeFdgState={stakingFdg.stakeState}
							stakeFdgClaim={stakingFdg.claim}
							stakeFdgClaimState={stakingFdg.claimState}
							unstakeFdg={stakingFdg.unstake}
							unstakeFdgState={stakingFdg.unstakeState}
							mint={initialMint}
							approveAll={approveAll}
							ckiLockRev={utils.ckiLockRev}
							ckiLockedRaw={lockingCki.rawStake}
							ckiLockedStake={lockingCki.ownStake}
							ckiLockedTotal={lockingCki.totalStake}
							fdgLockedRaw={lockingFdg.rawStake}
							fdgLockedStake={lockingFdg.ownStake}
							fdgLockedTotal={lockingFdg.totalStake}
							fdgLockRev={utils.fdgLockRev}
							ckiStakeApy={ckiStakeApy}
							fdgStakeApy={fdgStakeApy}
							ckiStakingClaimableRT={fdgStakingClaimableRT}
							fdgStakingClaimableRT={ckiStakingClaimableRT}
							hasMinted={ccDistr.hasMinted}
							balance={balance}
						/>
					}
				/>
			</Routes>
			{errorAlert.length > 0 && <ErrorAlert alert={errorAlert} setAlert={setAlert} />}
			<ModalWrapper
				txs={txs}
				setTxs={setTxs}
				removeTx={removeTx}
				refetchers={refetchers}
				lockingCki={lockingCki}
				lockingFdg={lockingFdg}
				stakingCki={stakingCki}
				stakingFdg={stakingFdg}
				compete={compete}
				utils={utils}
				ccDistr={ccDistr}
				approveState={approveState}
			/>
		</>
	);
}
